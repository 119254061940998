import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../context/context";
// import Form from "../Contact/Form";

const Modal = () => {
  const [showModal, setShowModal] = useState(true);
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      {showModal ? (
        <>
          <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-[#ffffff] ">
                    <div className="sm:flex sm:items-start">
                      <button
                        className="absolute top-3 right-3 px-2 rounded-full text-[#000] hover:text-[#00B3B3]"
                        id="modal-title"
                        onClick={() => setShowModal(false)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                      <div className="w-full flex justify-center item-center flex-col ">
                        <div className="w-full flex justify-center ">
                          {/* <img
                            src={`https://firebasestorage.googleapis.com/v0/b/videos-a7d6e.appspot.com/o/logo%20white.png?alt=media&token=b2e9bdd8-a435-4a2e-8811-1d85722b152a`}
                            className="md:w-[50%] w-[70%]"
                            alt="Not Found"
                            loading="lazy"
                          /> */}
                          <img
                            src={
                              !rpdata?.spanishVersion?.isActive
                                ? "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/Imagenes%20decoraciones%20angel%20massage%2Fpost%20catedral-01.jpg?alt=media&token=f8740957-162e-490a-8a95-f4bc4652d7d8"
                                : "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/5368532.jpg?alt=media&token=e31b78e4-084c-4ee1-a709-8e0c906bc44b"
                            }
                            alt=""
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <h4 className="text-center capitalize">
                    {
                      !rpdata?.spanishVersion?.isActive ? '¿Deseas que oremos por ti?' : 'You want us to pray for you?'
                    }
                    </h4>
                    <Form /> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
