import React from 'react'



const TraslateHome = ({ cambiarId }) => {

    //español
    const id1 = "/paginaspanish/63d7ec779bc299f93400ec47";
    // ingles
    const id2 = "/paginas/63d7e9fc9bc299f93400ebf9";

    return (
        <div
            className='w-full h-screen relative flex items-center bg-cover bg-left md:bg-center bg-no-repeat before:absolute before:bg-black before:w-full before:h-screen before:opacity-50'
            style={{
                backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/videos-a7d6e.appspot.com/o/dominicales%7D.jpg?alt=media&token=305b1385-81dc-490a-a307-95083b733670")`
            }}
        >
            <div className='relative flex flex-col items-center w-full'>
                <img
                    src={'https://firebasestorage.googleapis.com/v0/b/videos-a7d6e.appspot.com/o/IGLESIA%20CATEDRAL%20DE%20FE%20-%20wh.png?alt=media&token=d75c9737-c60e-45ef-a261-0e988b95659d'}
                    alt='no fount'
                    className='w-[80%] md:w-[30%] pb-5'
                />
                <h1 className='text-white capitalize pb-5'>Sitio / site</h1>
                <div className='flex'>
                    <div className='mr-3'>
                        <button
                            onClick={() => cambiarId({ id: id1, l: 'es' })}
                            className='px-5 py-3 rounded-xl bg-[#003C96] text-white'
                        >
                            Español
                        </button>
                    </div>
                    <div className='ml-3'>
                        <button
                            onClick={() => cambiarId({ id: id2, l: 'en' })}
                            className='px-5 py-3 rounded-xl bg-[#FF7D84] text-white'
                        >
                            English
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TraslateHome