import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/ValuesOne";
import BlockPrincipal from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockOne";


function About() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName={!rpdata?.spanishVersion?.isActive ? "Sobre Nosotros" : "About"}>
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle={!rpdata?.spanishVersion?.isActive ? "Sobre Nosotros" : "About"}
          Subheader={!rpdata?.spanishVersion?.isActive ? "Sobre Nosotros" : "About"}
          bgimg={`${rpdata?.stock?.[2]}`}
        />
        <BlockPrincipal
          title={!rpdata?.spanishVersion?.isActive ? 'un poco sobre nosotros' : 'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[11]}
          listsAbout
        />
        <ValuesOne image={rpdata?.gallery?.[8]} />

        <BlockOne
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.stock?.[3]}
        />
      </div>
    </BaseLayout>
  );
}

export default About;
