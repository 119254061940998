import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import GalleryComponent from "../components/Gallery/Gallery";
import BaseLayout from "../components/global/BaseLayout";

function Gallery() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName={!rpdata?.spanishVersion?.isActive ?"Galería" : "Gallery"}>
    <div className="md:max-w-full w-full">
      <TransparentHeader
        headertitle={!rpdata?.spanishVersion?.isActive ?"Galería" : "Gallery"}
        Subheader={!rpdata?.spanishVersion?.isActive ?"Galería" : "Gallery"}
          bgimg={`${rpdata?.stock?.[10]}`}
        />
        <GalleryComponent />
      </div>
    </BaseLayout>
  );
}

export default Gallery;
