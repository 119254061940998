import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockFour from "../components/block/Block_5";
import BlockPrincipal from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockOne";
import VideoPromocional from "../components/global/VideoPromocional";
import BloqueSecundario from "../components/block/Block_8"

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />

        {/* <HeroVideo
          urlVideo={'https://firebasestorage.googleapis.com/v0/b/videostock-8dab7.appspot.com/o/video%2Faire%20stock.mp4?alt=media&token=1702107a-2ca7-40a2-8544-029e64ff727f'}
        /> */}
        <HeroSection />

        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.stock?.[3]}
          sloganPrincipal={true}
          listsServices={true}
        />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[2]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }
        <BloqueSecundario
        title={rpdata?.dbSlogan?.[2]?.slogan}
        text={rpdata?.dbAbout?.[1].text}
        image1={rpdata?.stock?.[6]}
        image2={rpdata?.stock?.[1]}
        image3={rpdata?.stock?.[0]}
        image4={rpdata?.stock?.[5]}

        />

        <Directories />

        <BlockFour
          title={rpdata?.dbSlogan?.[4]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image={rpdata?.stock?.[10]}
        />

        <CounterUp image={rpdata?.gallery?.[0]} />

        <BlockOne
          title={!rpdata?.spanishVersion?.isActive ? 'acerca de nosotros' :'about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[15]}
          listsAbout={true}
        />

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center"> Our Reviews</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`} style={{overflow:'hidden'}}></div>
            </div>
            : null
        }
        
        <ServicesHome />

        {/* Paleta de Colores */}

        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[12]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
